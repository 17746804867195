// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
apiKey: "AIzaSyC3QczAGkn-NkC7n3zKQLWhPkqAzh0h6nM",
authDomain: "xplorauth.firebaseapp.com",
projectId: "xplorauth",
storageBucket: "xplorauth.appspot.com",
messagingSenderId: "1039411016337",
appId: "1:1039411016337:web:3bf1de5c7fbe69da8b0530"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app