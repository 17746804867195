import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('')
    try {
      await signIn(email, password)
      navigate('/account')
    } catch (e) {
      setError(e.message)
      console.log(e.message)
    }
  };

  return (
    <div class="flex flex-row h-screen">
      <div className="basis-1/2 bg-no-repeat bg-cover bg-center bg-gray-700 rounded-lg bg-[url('/public/Logo.jpg')]"></div>
      <div className='basis-1/3  m-auto'>
        <div>
          <h1 className='text-2xl font-bold py-2'>Sign in to your account</h1>
        </div>
        <form class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <div className='flex flex-col py-2'>
            <label class="block text-gray-700 text-sm font-bold mb-2 " for="username" htmlFor="username">Email Address</label>
            <input onChange={(e) => setEmail(e.target.value)} className='border p-3 rounded-lg shadow' type='email'placeholder="email address"  />
          </div>
          <div className='flex flex-col py-2'>
            <label htmlFor="password" className='py-2 font-medium'>Password</label>
            <input onChange={(e) => setPassword(e.target.value)} className='border p-3 rounded-lg shadow ' type='password'placeholder="password" />
          </div>
          <button className='border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white rounded-lg shadow'>
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signin;